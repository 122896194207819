import { useRef, useState, useEffect } from "react";
import {
    faCheck,
    faTimes,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ButtonFullWidth from "../ButtonFullWidth";
import Cross from "./Cross";
import Checkmark from "./Checkmark";
import axios from "axios";

const USER_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9]).{6,52}$/;
const COMPANY_REGEX = /^.{2,128}$/;
const REGISTER_URL = "/register";

const S3Registration = ({
    step,
    handlePrevStep,
    handleNextStep,
    sets3email,
    s1Form,
}) => {
    const navigate = useNavigate();
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState("");
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [company, setcompany] = useState("");
    const [companyValid, setcompanyValid] = useState(true);
    const [companyFocus, setcompanyFocus] = useState(false);

    const [pwd, setPwd] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [requirements, setRequirements] = useState({
        length: false,
        lowercaseAndNumber: false,
        specialCharacters: false,
    });

    const errorExistingUser =
        "An error occurred (UsernameExistsException) when calling the SignUp operation: An account with the given email already exists.";

    const handleErrorMessage = (error) => {
        const errorMessage = error.response.data;

        if (errorMessage === errorExistingUser) {
            return "Sorry it appears that email is already in use";
        }

        if (errorMessage) {
            let message = "Error: " + errorMessage;
            return message;
        }

        return error;
    };

    const isPwdValid = (password) => {
        const isLengthValid = password.length >= 6 && password.length <= 60;
        const isLowercaseAndNumberValid =
            /[a-z]/.test(password) && /\d/.test(password);
        // const isSpecialCharactersValid = /[!@#$%]/.test(password);

        setRequirements({
            length: isLengthValid,
            lowercaseAndNumber: isLowercaseAndNumberValid,
            // specialCharacters: isSpecialCharactersValid,
        });

        // return (
        //   isLengthValid && isLowercaseAndNumberValid && isSpecialCharactersValid
        // );
        return isLengthValid && isLowercaseAndNumberValid;
    };

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user]);

    useEffect(() => {
        setcompanyValid(COMPANY_REGEX.test(company));
    }, [company]);

    useEffect(() => {
        isPwdValid(pwd);
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        let apiSecret;
        let stopTriggerWord;
        if (s1Form.vendor === "SBM" || s1Form.vendor === "Bulletin") {
            apiSecret = "empty";
            stopTriggerWord = "empty";
        } else {
            apiSecret = s1Form.apiSecret;
            stopTriggerWord = "STOP";
        }

        const maxLength = 20;
        const apiKeyTruncated =
            s1Form.apiKey.length > maxLength
                ? s1Form.apiKey.substring(0, maxLength) + "..."
                : s1Form.apiKey;

        const userAttributes = [
            {
                Name: "custom:api_key_truncated",
                Value: apiKeyTruncated,
            },
            {
                Name: "custom:api_key",
                Value: s1Form.apiKey,
            },
            {
                Name: "custom:api_secret",
                Value: apiSecret,
            },
            {
                Name: "custom:company",
                Value: company,
            },
            {
                Name: "custom:stop_trigger_word",
                Value: stopTriggerWord,
            },
            {
                Name: "custom:vendor",
                Value: s1Form.vendor,
            },
            {
                Name: "email",
                Value: user,
            },
        ];

        const formData = {
            vendor: s1Form.vendor,
            user_attributes: userAttributes,
            api_key: s1Form.apiKey,
            email: user,
            password: pwd,
        };

        axios
            .post(import.meta.env.VITE_SIGNUP, JSON.stringify(formData))
            .then((response) => {
                console.log(response);
                toast.success("Successfully registered", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                sets3email(user);

                setUser("");
                setPwd("");
                setMatchPwd("");

                handleNextStep();
            })
            .catch((error) => {
                if (error) {
                    console.error(error);
                    const errorMessage = handleErrorMessage(error);

                    toast.error("Error:" + errorMessage, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            });
    };

    return (
        <>
            <ToastContainer />
            <form onSubmit={handleSubmit}>
                <div className="flex-1">
                    <div className="mt-6">
                        {/* company */}
                        <div className="pb-4 relative">
                            <label htmlFor="company">
                                Business Name:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        companyValid
                                            ? "text-green-600 ml-1"
                                            : "hidden"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        companyValid || !company
                                            ? "hidden"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full 
									focus:outline-none focus:border-primary"
                                type="text"
                                id="company"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setcompany(e.target.value)}
                                value={company}
                                required
                                ml-1={companyValid ? "false" : "true"}
                                aria-describedby="companynote"
                                onFocus={() => setcompanyFocus(true)}
                                onBlur={() => setcompanyFocus(false)}
                            />
                            <p
                                id="companynote"
                                className={
                                    companyFocus && company && !companyValid
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 mt-1 bottom-20 absolute"
                                        : "sr-only"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                Valid business name required
                            </p>
                        </div>
                        {/* username */}
                        <div className="pb-4 relative">
                            <label htmlFor="username">
                                Email:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        validName
                                            ? "text-green-600 ml-1"
                                            : "hidden"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        validName || !user
                                            ? "hidden"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full  
									focus:outline-none focus:border-primary"
                                type="text"
                                id="username"
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                                ml-1={validName ? "false" : "true"}
                                aria-describedby="uidnote"
                                onFocus={() => setUserFocus(true)}
                                onBlur={() => setUserFocus(false)}
                            />
                            <p
                                id="uidnote"
                                className={
                                    userFocus && user && !validName
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 mt-1 bottom-20 absolute"
                                        : "sr-only"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                Valid email required
                            </p>
                        </div>
                        {/* Password */}
                        <div className="pb-4 relative">
                            <p
                                id="pwdnote"
                                className={
                                    pwdFocus &&
                                    Object.values(requirements).some(
                                        (requirement) => !requirement
                                    )
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 -mt-14 absolute"
                                        : "sr-only"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                <br />
                                <span
                                    aria-label="check for if valid"
                                    className="text-green-600"
                                >
                                    {requirements.length ? (
                                        <Checkmark check={true} />
                                    ) : (
                                        <Cross check={true} />
                                    )}{" "}
                                </span>
                                6 to 60 characters.
                                <br />
                                <span
                                    aria-label="check for if valid"
                                    className="text-green-600"
                                >
                                    {requirements.lowercaseAndNumber ? (
                                        <Checkmark check={true} />
                                    ) : (
                                        <Cross check={true} />
                                    )}{" "}
                                </span>
                                Must include a letter and a number.
                                {/* <br />
                <span
                  aria-label="check for if valid"
                  className="text-green-600"
                >
                  {requirements.specialCharacters ? (
                    <Checkmark check={true} />
                  ) : (
                    <Cross check={true} />
                  )}{" "}
                </span>
                Allowed special characters:{" "}
                <span aria-label="exclamation mark">!</span>{" "}
                <span aria-label="at symbol">@</span>{" "}
                <span aria-label="hashtag">#</span>{" "}
                <span aria-label="dollar sign">$</span>{" "}
                <span aria-label="percent">%</span> */}
                            </p>
                            <label htmlFor="password">
                                Password:
                                <Checkmark check={validPwd} />
                                <Cross check={validPwd || !pwd} />
                            </label>
                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full
									focus:outline-none focus:border-primary"
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                                ml-1={validPwd ? "false" : "true"}
                                aria-describedby="pwdnote"
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                            />
                        </div>
                        {/* Confirm Password */}
                        <div className="pb-4">
                            <p
                                id="apikeynote"
                                className={
                                    matchFocus && !validMatch
                                        ? "text-xs rounded-md bg-gray-400 text-white px-1 py-1 -mt-6 absolute"
                                        : "sr-only"
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faInfoCircle}
                                    className="mr-1"
                                />
                                Must match the first password input field.
                            </p>
                            <label htmlFor="confirm_pwd">
                                Confirm Password:
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className={
                                        validMatch && matchPwd
                                            ? "text-green-600 ml-1"
                                            : "hidden"
                                    }
                                />
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    className={
                                        validMatch || !matchPwd
                                            ? "hidden"
                                            : "text-red-600 ml-1"
                                    }
                                />
                            </label>

                            <input
                                className="border-2 border-gray-500 p-2 rounded-md w-full 
									focus:outline-none focus:border-primary"
                                type="password"
                                id="confirm_pwd"
                                onChange={(e) => setMatchPwd(e.target.value)}
                                value={matchPwd}
                                required
                                ml-1={validMatch ? "false" : "true"}
                                aria-describedby="confirmnote"
                                onFocus={() => setMatchFocus(true)}
                                onBlur={() => setMatchFocus(false)}
                            />
                        </div>

                        <div className="flex gap-x-5 mx-auto lg:mx-12">
                            <ButtonFullWidth
                                type="button"
                                message="Previous"
                                action={handlePrevStep}
                            />
                            <ButtonFullWidth
                                type="submit"
                                message="Register"
                                className="border-gray-400 bg-gray-400 text-white"
                                applyClassName={
                                    !validName || !validPwd || !validMatch
                                }
                                disabled={
                                    !validName || !validPwd || !validMatch
                                }
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default S3Registration;
