import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function PopUp({ popUpType, popUp, onClose }) {
	const time = 10000;
	const [visible, setVisible] = useState(true);
	const [timeLeft, setTimeLeft] = useState(time);
	const [popUpColor, setPopUpColor] = useState("");
	
	useEffect(() => {
		if (popUpType === "error") {
			setPopUpColor("bg-red-500");
		} else if (popUpType === "success") {
			setPopUpColor("bg-green-500");
		}
	}, []);

	useEffect(() => {
		// After 5 seconds, hide the error component
		const timeoutId = setTimeout(() => {
			setVisible(false);
			onClose();
		}, time);

		// Cleanup function to clear the timeout when the component unmounts
		return () => {
			clearTimeout(timeoutId);
		};
	}, [onClose]);

	useEffect(() => {
		if (timeLeft <= 0) {
		} else {
			const timer = setTimeout(() => {
				setTimeLeft(timeLeft - 1000);
			}, 1000);

			return () => clearTimeout(timer);
		}
	}, [timeLeft, onClose]);

	useEffect(() => {
		setTimeLeft(time - 1000);
	}, []);

	return visible ? (
		<div
			className={`z-40 fixed right-5 top-5 ${popUpColor} text-white rounded-md shadow-lg items-center max-w-sm`}
		>
			<div className="flex justify-between gap-4 items-center p-3">
				<span className="text-sm max-w-md">{popUp}</span>
				<button
					className="border-none hover:border-none"
					onClick={() => {
						setVisible(false);
						onClose();
					}}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth="1.5"
						stroke="currentColor"
						className="w-6 h-6 hover:text-gray-200"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				</button>
			</div>
			<div className="relative h-1">
				<div
					className="absolute bottom-0 left-0 h-1 bg-white ease-linear duration-1000"
					style={{ width: `${(timeLeft / time) * 100}%` }}
				></div>
			</div>
		</div>
	) : null;
}
