import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Checkmark({ check }) {
  return (
    <>
      <FontAwesomeIcon
        icon={faCheck}
        className={check ? "text-green-600 ml-1" : "hidden"}
      />
    </>
  );
}
