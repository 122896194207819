import React from "react";
import { Link } from "react-router-dom";
import S4Registration from "./SbmgRegisterForm/S4Registration";

export default function SbmgRegister() {
	return (
			<section className="m-auto h-screen items-center flex justify-center rounded-lg drop-shadow-md text-gray-700 w-5/6 max-w-6xl">
				<div className="flex-col flex bg-white rounded-lg py-6 px-12 w-full gap-3 text-sm">
					<h1 className="text-3xl text-center">Subscribe</h1>

					<S4Registration />

					<div className="text-center text-sm mt-6 w-full">
						<p>Already registered?</p>
						<Link to="https://app.jungledrum.com/" className="text-primary">
							Sign In
						</Link>
					</div>
				</div>
			</section>
	);
}
