import React, { useState } from "react";
import ButtonFullWidth from "../ButtonFullWidth";

export default function ToSRegistration({
    handlePrevStep,
    handleNextStep,
    step,
}) {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    return (
        <>
            <div
                className='flex flex-col mx-auto gap-3 px-5 max-w-2xl overflow-hidden items-center'
            >
                <div className="w-full flex flex-col max-w-lg lg:max-w-2xl h-56 2xl:h-72 gap-5">
                    {/* <h2 className="text-lg">
                        To continue accept Terms of services
                    </h2> */}
                    <iframe
                        src="/SwiftSurveyTermsAndConditions.pdf"
                        width="100%"
                        height="100%"
                    ></iframe>
                </div>
                <label>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="accent-primary mr-3"
                    />
                    I accept the Terms and Conditions
                </label>
            </div>

            <div className="flex gap-x-5 mx-12">
                <ButtonFullWidth
                    type="button"
                    message="Previous"
                    action={handlePrevStep}
                    className={`${
                        step === 1
                            ? "bg-gray-300 text-gray-500 border-gray-300"
                            : null
                    }`}
                    applyClassName={step === 0}
                    disabled={step === 0}
                />
                <ButtonFullWidth
                    type="button"
                    message="Next"
                    action={handleNextStep}
                    applyClassName={!isChecked}
                    className={"border-gray-500 text-gray-500"}
                    disabled={!isChecked}
                />
            </div>
        </>
    );
}
