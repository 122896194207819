import { useState } from "react";
// import PopUp from "./PopUp";
import S1Registration from "./SsRegisterForm/S1Registration";
import S2Registration from "./SsRegisterForm/S2Registration";
import S3Registration from "./SsRegisterForm/S3Registration";
import Stepper from "./SsRegisterForm/Stepper";
import { Link } from "react-router-dom";
import S4Registration from "./SsRegisterForm/S4Registration";
import ToSRegistration from "./SsRegisterForm/ToSRegistration";
import S3aRegistration from "./SsRegisterForm/S3aRegistration";

import posthog from 'posthog-js'

export default function SsRegister() {
	const [s1Form, sets1Form] = useState({
		vendor: "",
		apiKey: "",
		apiSecret: "",
	});

	const [s3Email, sets3email] = useState("");

	const [step, setStep] = useState(0);

	const handleNextStep = () => {
		setStep((prevStep) => prevStep + 1);
	};

	const handlePrevStep = () => {
		setStep((prevStep) => prevStep - 1);
	};

	const handleNextSubStep = () => {
		setStep((prevStep) => prevStep + 0.5);
	};

	const handlePrevSubStep = () => {
		setStep((prevStep) => prevStep - 0.5);
	};

	return (
		<>
			{/* {popUp && (
				<PopUp
					popUpType={popUpType}
					popUp={popUp}
					onClose={() => setPopUp(null)}
				/>
			)} */}
			<section
				className={`m-auto h-screen items-center flex justify-center rounded-lg drop-shadow-md text-gray-700 ${
					step === 4 ? `w-5/6 max-w-6xl` : `w-3/4`
				}`}
			>
				<div className="bg-white rounded-lg py-6 px-12 w-full text-sm">
					<h1 className="text-3xl text-center">Register</h1>
					<Stepper step={step} />
					{step === 0 && (
						<ToSRegistration
							step={step}
							handlePrevStep={handlePrevStep}
							handleNextStep={handleNextStep}
						/>
					)}
					{step === 1 && (
						<S1Registration
							step={step}
							handlePrevStep={handlePrevStep}
							handleNextStep={handleNextStep}
							s1Form={s1Form}
							sets1Form={sets1Form}
						/>
					)}
					{step === 2 && (
						<S2Registration
							step={step}
							handlePrevStep={handlePrevStep}
							handleNextStep={handleNextStep}
							s1Form={s1Form}
						/>
					)}
					{step === 3 && (
						<S3Registration
							step={step}
							handlePrevStep={handlePrevStep}
							handleNextStep={handleNextSubStep}
							sets3email={sets3email}
							s1Form={s1Form}
						/>
					)}
					{step === 3.5 && (
						<S3aRegistration
							step={step}
							handlePrevStep={handlePrevSubStep}
							handleNextStep={handleNextSubStep}
						/>
					)}
					{step === 4 && (
						<S4Registration
							handlePrevStep={handlePrevStep}
							s3Email={s3Email}
						/>
					)}
					<div className="text-center text-sm mt-6 w-full">
						<p>Already registered?</p>
						<Link to="https://app.swiftsurvey.co.nz" className="text-primary">
							Sign In
						</Link>
					</div>
				</div>
			</section>
		</>
	);
}
