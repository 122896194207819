import { useLocation, Outlet } from "react-router-dom";

const Layout = () => {
	return (
		<>
			<main className="items-center w-full bg-stone-100">
				<Outlet />
			</main>
		</>
	);
};

export default Layout;
