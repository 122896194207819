import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";

import Missing from "./Missing";
import SbmgRegister from "./SBMGRegister";
import SsRegister from "./SsRegister";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="" element={<Missing />} />
                {/* public routes */}
                <Route path="swiftsurvey" element={<SsRegister />} />
                <Route path="sbmg" element={<SbmgRegister />} />

                {/* catch all */}
                <Route path="*" element={<Missing />} />
            </Route>
        </Routes>
    );
}

export default App;
