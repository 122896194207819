import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';

import posthog from 'posthog-js'

posthog.init('phc_Vqy2I4mSId5XHAXP03b4WyiRBxGMFig9hQvkUbiv4wR', { api_host: 'https://eu.posthog.com' })

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
  </BrowserRouter>
</React.StrictMode>,
);

