import React from "react";

export default function S4Registration() {
    return (
        <>
            <stripe-pricing-table
                pricing-table-id={import.meta.env.VITE_STRIPE_SBMG_PRICING_TABLE_ID}
                publishable-key={import.meta.env.VITE_STRIPE_SBMG_PUBLISHABLE_KEY}
            ></stripe-pricing-table>
        </>
    );
}
