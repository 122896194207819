import { Link } from "react-router-dom";

const Missing = () => {
    return (
        // <div className="flex flex-col items-center m-auto">
        // 	<h1 className="text-5xl">Oops!</h1>
        // 	<p>Page Not Found</p>
        // 	<Link to="/">Click here to go back to Dashboard</Link>
        // </div>

        <section className="m-auto h-screen items-center flex justify-center rounded-lg drop-shadow-md text-gray-700 w-5/6 max-w-6xl">
            <div className="flex-col flex bg-white rounded-lg py-6 px-12 w-full gap-3 text-sm">
                <h1 className="text-5xl">Oops!</h1>
                <p>Product Not Found</p>

                <Link
                    to="https://www.apishift.co.nz/marketplace"
                    className="text-primary"
                >
                    Click here to go back to the Marketplace
                </Link>
            </div>
        </section>
    );
};

export default Missing;
