import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Cross({ check }) {
  return (
    <>
      <FontAwesomeIcon
        icon={faTimes}
        className={check ? "hidden" : "text-red-600 ml-1"}
      />
    </>
  );
}
