import React, { useRef, useState, useEffect } from "react";

import ButtonFullWidth from "../ButtonFullWidth";

const S3aRegistration = ({ handlePrevStep, handleNextStep }) => {
    const handleSubmit = async (e) => {
        handleNextStep();
    };
    
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex-1">
                    <div className="mt-6">
                        <p className="max-w-fit mx-auto my-3">
                            To verify your account we have emailed a verificaiton
                            link, it will redirect you to a screen that says
                            "Your registration has been confirmed!"
                        </p>
                        <div className="aspect-video mx-auto max-h-48">
                            <img
                                className="h-auto w-auto"
                                src="/confirmation.png"
                                alt="Image of text box with example confirmation message"
                            />
                        </div>
                        <div className="flex gap-x-5 mx-auto lg:mx-12">
                            <ButtonFullWidth
                                type="button"
                                message="Previous"
                                action={handlePrevStep}
                            />

                            <ButtonFullWidth
                                type="button"
                                message="Next"
                                action={handleNextStep}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default S3aRegistration;
