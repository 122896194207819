// import React from "react";
import PropTypes from "prop-types";

export default function S4Registration({ s3Email }) {
    S4Registration.propTypes = {
        s3Email: PropTypes.any,
    };
    return (
        <>
            <stripe-pricing-table
                pricing-table-id={import.meta.env.VITE_STRIPE_PRICING_TABLE_ID}
                publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
                customer-email={s3Email}
            ></stripe-pricing-table>
        </>
    );
}
